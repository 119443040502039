.rowWrapper {
    display: flex;
    flex-direction: column;
}

.resultTitleRow {
    display: flex;
    flex-direction: row;
    height: 40px;
    align-items: center;
}

.rowID {
    width: 100px;
    /* flex:1; */
    padding-left: 10px;
}

.rowAddress {
    width: 400px;
    /* flex: 5; */
    padding-left: 10px;
}
.rowBalance {
    width: 250px;
    word-wrap: break-word;
    /* flex:3; */
    padding-left: 10px;
}